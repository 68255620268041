
<template lang="html">
    <div>
        <app-nav-bar></app-nav-bar>
        <div class="about-us-bg">
            <b-container>
                <h1>About Us</h1>
                <b-card>
                    <b-card-title>
                        "Unleashing the Power of Sapphire: Your Pathway to Digital Excellence"
                    </b-card-title>
                    <b-card-text>
                        Sapphire Software (M) Sdn Bhd is a dynamic and innovative software development company specializing in MLM, Cryptocurrency, Trading and investment, Digital Marketing with our “fast and furious” development for more than 8 years experience. With a focus on delivering exceptional software products and services, Sapphire Software has established itself as a trusted partner for businesses seeking to leverage technology to deliver robust and customized software applications.
                    </b-card-text>
                    <b-card-text>
                        At Sapphire Software, we pride ourselves on our commitment to excellence and customer satisfaction. Our team of skilled and experienced professionals works diligently to understand the unique needs and challenges of our clients, providing tailored software solutions that drive efficiency, productivity, and growth.
                    </b-card-text>
                    <b-card-text>
                        As an industry leader, Sapphire Software stays at the forefront of technological advancements, leveraging emerging technologies such as artificial intelligence, machine learning, blockchain, and cloud computing to develop innovative software solutions. We continuously strive to leverage the latest technologies and industry best practices to create solutions that empower our clients to stay competitive and achieve their business objectives.
                    </b-card-text>
                    <b-card-text>
                        We prioritize delivering exceptional value to our clients. Our solutions are known for their reliability, scalability, and user-friendly interfaces, empowering businesses to stay ahead of the competition in today's rapidly evolving digital world.
                    </b-card-text>
                    <b-card-text>
                        Choose Sapphire Software (M) Sdn Bhd as your trusted technology partner and embark on a transformative journey towards digital excellence. We’re building a culture for business to thrive in the digital world Our products
                    </b-card-text>
                    <b-card-text>
                        We provide an extensive array of software products encompassing blockchain, MLM, e-commerce, mobile app and website development. These products are meticulously crafted using state-of-the-art technologies to ensure optimal efficiency, unwavering reliability and seamless scalability.
                    </b-card-text>
                </b-card>
            </b-container>
            
            <app-footer></app-footer>
        </div>
    </div>
    </template>
    
    <script>
    export default {
      components: {
          appNavBar: () => import('@/components/Navbar'),
          appFooter: () => import('@/components/Footer')
        }
    
    }
    </script>
    
    <style lang="css" scoped>
    .about-us-bg{
        background-image: url(/img/Background.png);
        background-size: cover; 
        background-position: center center;
        padding:15px;
    }
    </style>
    